
export default {
  name: 'Home',
  props: {
  },
  components: {
  },
  data() {
    return {
    }
  },
  methods: {

  },
  async created() {
  },
  mounted () {
  }
}
